<template>
	<div class="tags-input">
		<input type="text" class="tags-input-text form-control" :placeholder="placeholder" v-model="newTag" v-on:keydown="onKeydown" />
		<small v-if="newTag != ''"><span class="text-danger">*</span> Press enter to save addon.</small>
		<div class="border mt-2 p-10 tags-content" v-show="TagsList.length > 0">
			<span v-for="tag in TagsList" class="tags-input-tag" v-bind:key="tag">
				<i v-on:click="removeTag(tag)">&times;</i>
				<span>{{ tag }}</span>
			</span>
		</div>
	</div>
</template>
<script>
export default {
	model: {
		prop: 'tags', event: 'update'
	},
	props: {
		deleteKey	: { type: Array, default: () => ['8'] },
		addKey		: { type: Array, default: () => ['13'] },
		tags		: { required: true },
		placeholder	: { type: String, default: 'Add tag...' }
	},
	data() {
		return {
			newTag	: '',
			TagsList: []
		}
	},
	watch: {
		tags: {
			handler(val) {
				this.TagsList = val
			},
			deep: true
		},
	},
	mounted() {
		// this.TagsList = this.tags
	},
	methods: {
		handleTagBackspace(e) {
			console.log(e)
			if (this.newTag.length === 0) {
				
				this.TagsList = this.TagsList.slice(0, -1)
				this.$emit('update', this.TagsList)
				
				// this.$emit('update', this.tags.slice(0, -1))
			}
		},
		addTag() {
			if (this.newTag.length === 0 || this.TagsList.includes(this.newTag)) {
				return
			}
			this.TagsList = [...this.TagsList, this.newTag]
			this.$emit('update', this.TagsList)
			// this.$emit('update', [...this.TagsList, this.newTag])
			this.newTag = ''
		},
		removeTag(tag) {
			this.TagsList = this.TagsList.filter(t => t !== tag)
			this.$emit('update', this.TagsList)
			
			// this.$emit('update', this.TagsList.filter(t => t !== tag))
		},
		onKeydown(e) {
			if(this.deleteKey.includes(e.keyCode.toString())) {
				this.handleTagBackspace()
			}
			if(this.addKey.includes(e.keyCode.toString())) {
				this.addTag()
				e.preventDefault()
			}
			if(e.keyCode === 13 && this.deleteKey !== 13) {
				e.preventDefault()
			}
		}
	}
}
</script>

<style lang="scss">
	.tags-content {
		background-color: #f9f9f9;
	}
	.tags-input-tag {
		background-color: #2194e7;
		color: #fff;
		border-radius: 4px;
		padding: 0px 10px;
		margin-right: 4px;
		margin-bottom: 4px;
		display: inline-block;
		box-shadow: 1px 1px 1px #eee;
		&:hover {
			box-shadow: 1px 1px 1px #ddd;
		}
		i {
			padding-right: 8px;
			cursor: pointer;
		}
		span {
			vertical-align: text-bottom;
		}
	}
</style>
